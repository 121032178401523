import { useState, useEffect } from "react";
import env from "react-dotenv";
import StoreTable from './StoreTable';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Autocomplete from "react-google-autocomplete";
import Swal from 'sweetalert2';

function Stores() {

  const [tableItems, setTableItems] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState({});
  const [storeCreate, setStoreCreate] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth0();

  const [permissions] = useOutletContext();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const create = async () => {
    setLoading(true);
    setStoreCreate(false);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const store = await fetch(env.AUTH0_API_SERVER+"stores", {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        name: name,
        email: email,
        address: address,
      }),
    }).then((res) => res.json());
    const localTableItems = [...tableItems];
    localTableItems.push(store);
    setTableItems(localTableItems);
    setStoreCreate(true);
    setName('');
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      const stores = await fetch(env.AUTH0_API_SERVER+"stores", {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
      }).then((res) => res.json());
      setTableItems(stores);
      setLoading(false);
    };
    initData();
  }, [auth])

  return (
    <section id="stores">
      {!loading && permissions?.findIndex((x) => x.permission_name === 'read:stores') >= 0 && tableItems.length > 0 && <div className="affiliation-section">
        <StoreTable rowsPerPage={isMobile ? '5' : '10'} data={tableItems} permissions={permissions} />
      </div>}
      {!loading && permissions?.findIndex((x) => x.permission_name === 'manage:stores') >= 0 && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('create')}</h2>
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('name')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('email')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('address')}</p>
              </td>
              <td>
                <Autocomplete className="affiliation-input" defaultValue={address?.formatted_address} apiKey={env.GMAP_KEY} language="it" onPlaceSelected={(place) => setAddress(place)} options={{types: []}} />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3">
          <button disabled={!name || !email || !address} className="btn btn-lg item-button" onClick={() => confirm(() => create())}>{t('confirm')}</button>
        </div>
        {storeCreate && <small>{t('created_store')}</small>}
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Stores;
