import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import Resizer from "react-image-file-resizer";
import Swal from 'sweetalert2';

function Store() {

  const [name, setName] = useState('');
  const [address, setAddress] = useState({});
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [site, setSite] = useState('');
  const [chat, setChat] = useState('no');
  const [image, setImage] = useState('');
  const [updating, setUpdating] = useState(false);
  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validSite, setValidSite] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [store, membership, loadUser] = useOutletContext();

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const update = async () => {
    setLoading(true);
    setValidPhone(true);
    setValidEmail(true);
    setValidSite(true);
    if (phone !== '' && !/[+]{0,1}[0-9]+/.test(phone)) {
      setValidPhone(false);
    } else if (email !== '' && !/\S+@\S+\.\S+/.test(email)) {
      setValidEmail(false);
    } else if (site !== '' && !/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi.test(site)) {
      setValidSite(false);
    } else {
      setUpdating(false);
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      await fetch(env.AUTH0_API_SERVER+"info/"+store.code, {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
        method: 'post',
        body: JSON.stringify({
          name: name,
          address: address,
          phone: phone,
          email: email,
          site: site,
          image: image,
          chat: chat === 'yes',
        }),
      }).then((res) => res.json());
      await loadUser();
    }
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        setName(store.name);
        setAddress(store.address ?? {});
        setPhone(store.phone ?? '');
        setEmail(store.email ?? '');
        setSite(store.site ?? '');
        setImage(store.image ?? '');
        setChat(store.preferences?.chat ? 'yes' : 'no');
        setLoading(false);
      }
    };
    initData();
  }, [auth, store])

  return (
    <section id="home">
      {!loading && (membership?.role === 'admin' || membership?.role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('store')}</h2>
        <div className="mt-5">
          {updating && <button className="btn item-button-success" onClick={ () => confirm(() => update()) } disabled={!name || !address?.formatted_address} data-bs-toggle="tooltip" data-bs-placement="right" title={t('confirm')}>{t('confirm')}</button>}
          {updating && <button className="btn item-button" onClick={ () => setUpdating(false) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('cancel')}>{t('cancel')}</button>}
          {!updating && membership?.role === 'admin' && <button className="btn item-button" onClick={ () => setUpdating(true) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>{t('update')}</button>}
          <table className="table">
            <tbody>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('name')}</p>
                </td>
                <td>
                  {updating && <input className="affiliation-input" value={name} onChange={(e) => setName(e.target.value)} />}
                  {!updating && <span>{store.name}</span>}
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('address')}</p>
                </td>
                <td>
                  {updating && <Autocomplete className="affiliation-input" defaultValue={address?.formatted_address} apiKey={env.GMAP_KEY} language="it" onPlaceSelected={(place) => setAddress(place)} options={{types: []}} />}
                  {!updating && <span>{store.address?.formatted_address}</span>}
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('phone')}</p>
                </td>
                <td>
                  {updating && <input className="affiliation-input" value={phone} onChange={(e) => setPhone(e.target.value)} />}
                  {updating && !validPhone && <p><small>{t('valid_phone')}</small></p>}
                  {!updating && <span>{store.phone}</span>}
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('email')}</p>
                </td>
                <td>
                  {updating && <input className="affiliation-input" value={email} onChange={(e) => setEmail(e.target.value)} />}
                  {updating && !validEmail && <p><small>{t('valid_email')}</small></p>}
                  {!updating && <span>{store.email}</span>}
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('site')}</p>
                </td>
                <td>
                  {updating && <input className="affiliation-input" value={site} onChange={(e) => setSite(e.target.value)} />}
                  {updating && !validSite && <p><small>{t('valid_url')}</small></p>}
                  {!updating && <span>{store.site}</span>}
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('image')}</p>
                </td>
                <td>
                  {updating && !image && <input className="affiliation-input" type="file" onChange={e => Resizer.imageFileResizer(e.target.files[0], 256, 256, "PNG", 100, 0, (x) => setImage(x), "base64", 256, 256)} />}
                  {updating && image && <img src={image} alt="" height="100" />}
                  {updating && image && <button className="btn btn-sm item-button-danger" onClick={ () => setImage('') } data-bs-toggle="tooltip" data-bs-placement="right" title={t('remove')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                    </svg>
                  </button>}
                  {!updating && store.image && <img src={store.image} alt="" height="100" />}
                  {!updating && !store.image && <p>{t('no_image')}</p>}
                </td>
              </tr>
              <tr>
                <td style={{width: '100px',}}>
                  <p>{t('chat')}</p>
                </td>
                <td>
                  {updating && <select className="affiliation-input" value={chat} onChange={e => setChat(e.target.value)}>
                    <option value="no">{t('no')}</option>
                    <option value="yes">{t('yes')}</option>
                  </select>}
                  {!updating && <span>{t(chat)}</span>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>}
      {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
    </section>
  );
}

export default Store;
