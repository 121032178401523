import { useState, useEffect } from "react";
import env from "react-dotenv";
import CodeReader from './CodeReader';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from "react-router-dom";
import { isMobile, MobileView } from 'react-device-detect';
import { CSVLink } from "react-csv";
import QRCode from "react-qr-code";
import { useDebouncedCallback } from 'use-debounce';
import Swal from 'sweetalert2';

function Service(props) {

  const [cost, setCost] = useState(0);
  const [pages, setPages] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [camera, setCamera] = useState(false);
  const [values, setValues] = useState([]);
  const [alias, setAlias] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [initialValue, setInitialValue] = useState(0);
  const [maxRegistrations, setMaxRegistrations] = useState(1);
  const [digital, setDigital] = useState(0);
  const [thresholds, setThresholds] = useState([]);
  const [cardsCreate, setCardsCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(-1);
  const [cardLoading, setCardLoading] = useState(-1);

  const { t } = useTranslation();

  const [store, membership, loadUser] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || 1);
  const search = searchParams.get('search') || '';

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      text: cost > 0 ? t('operation_cost', {cost: cost}) : '',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const confirmCard = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const handleSearchScan = (v) => {
    document.getElementById('search-input').value = v;
    reloadSearch(v);
    setCamera(false);
  };

  const reloadPage = (p) => {
    setSearchParams(params => {
      params.set('page', p);
      if (!!search) {
        params.set('search', search);
      }
      return params;
    });
  };

  const reloadSearch = useDebouncedCallback((s) => {
    setSearchParams(params => {
      params.set('page', 1);
      params.set('search', s);
      return params;
    });
  }, 500);

  const add = async (index, item, q) => {
    setCardLoading(index);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const card = await fetch(env.AUTH0_API_SERVER+"cards/"+store.code+"/"+item.code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        alias: item.alias,
        service: props.service.code,
        value: q,
      }),
    }).then((res) => res.json());
    const localData = [...data];
    const i = localData.findIndex((x) => x.code === card.code);
    localData[i] = card;
    setData(localData);
    const localValues = [...values];
    localValues[index] = 0
    setValues(localValues);
    setCardLoading(-1);
  };

  const consume = async (index, item, q) => {
    setCardLoading(index);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const card = await fetch(env.AUTH0_API_SERVER+"cards/"+store.code+"/"+item.code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        alias: item.alias,
        service: props.service.code,
        value: q,
      }),
    }).then((res) => res.json());
    const localData = [...data];
    const i = localData.findIndex((x) => x.code === card.code);
    localData[i] = card;
    setData(localData);
    const localValues = [...values];
    localValues[index] = 0
    setValues(localValues);
    setCardLoading(-1);
  };

  const create = async () => {
    setLoading(true);
    setCardsCreate(false);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"cards/"+store.code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        service: props.service.code,
        quantity: quantity,
        initial_value: initialValue,
        digital: digital,
        max_registrations: maxRegistrations,
        thresholds: thresholds,
      }),
    }).then((res) => res.json());
    await loadUser();
    setQuantity(0);
    setInitialValue(0);
    setMaxRegistrations(1);
    setDigital(0);
    setThresholds([]);
    setCardsCreate(true);
    setLoading(false);
  };

  const update = async (item) => {
    setUpdating(-1);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const card = await fetch(env.AUTH0_API_SERVER+"cards/"+store.code+"/"+item.code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        alias: alias,
        renew_secret: false,
      }),
    }).then((res) => res.json());
    const localData = [...data];
    const i = localData.findIndex((x) => x.code === card.code);
    localData[i] = card;
    setData(localData);
  };

  const renewSecret = async (item) => {
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const card = await fetch(env.AUTH0_API_SERVER+"cards/"+store.code+"/"+item.code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        alias: item.alias,
        renew_secret: true,
      }),
    }).then((res) => res.json());
    const localData = [...data];
    const i = localData.findIndex((x) => x.code === card.code);
    localData[i] = card;
    setData(localData);
  };

  const toggleUpdate = (index) => {
    setUpdating(index);
    setAlias(data[index].alias);
  }

  const cancelUpdate = () => {
    setUpdating(-1);
    setAlias('');
  }

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        setCost(0);
        //const priceList = await fetch(env.AUTH0_API_SERVER+"price-lists/card", {
        //  headers: {
        //    "Content-Type":"application/json",
        //    "Authorization": "Bearer "+accessToken,
        //  },
        //}).then((res) => res.json());
        //setCost(priceList.cost);
        const cards = await fetch(env.AUTH0_API_SERVER+"cards/"+window.sessionStorage.getItem('store')+"/search/"+props.service.code+"?"+new URLSearchParams({page: page, per_page: isMobile ? 5 : 10, search: search}).toString(), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setPages(cards.pages);
        setData(cards.data);
        setValues(Array(cards.data.length).fill(0));
        setTotal(cards.data.reduce((a, b) => a*1 + b[props.service.code].balance*1, 0));
        setLoading(false);
      }
    };
    initData();
  }, [auth, props.service.code, page, search])

  return (
    <section id="service">
      {props.service?.code && store?.services && !store.services[props.service.code] && (membership?.role === 'admin' || membership?.role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t(props.service.code)}</h2>
        <p>{t('inactive_service')}</p>
      </div>}
      {props.service?.code && store?.services && store.services[props.service.code] && (membership?.role === 'admin' || membership?.role === 'user') && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t(props.service.code)}</h2>
        <input id="search-input" placeholder={t('search')} onChange={(e) => reloadSearch(e.target.value)} />
        <MobileView>
          <button className="btn item-button mt-3" onClick={ () => setCamera(!camera) }>{camera ? t('stop_scan') : t('start_scan')}</button>
          {camera && <CodeReader handleCode={(v) => handleSearchScan(v)}/>}
        </MobileView>
        <div className="mt-5">
          {!loading && <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('card')}</th>
                <th scope="col">{t('manage')}</th>
              </tr>
            </thead>
            {data.length > 0 && <tbody>
              {data.map((el, k) => (
                <tr key={k}>
                  <td>
                    <div>
                      <div className="mt-1">
                        <b className={props.service.consumable && el[props.service.code]?.consumed && el[props.service.code]?.balance <= 0 ? 'text-decoration-line-through' : ''}>{el.code}</b>
                      </div>
                      {updating !== k && <div className="mt-1">
                        <i>{el.alias} </i>
                        <button className="btn btn-sm item-button" onClick={ () => toggleUpdate(k) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                          </svg>
                        </button>
                      </div>}
                      {updating === k && <div className="mt-1">
                        <input value={alias} onChange={(e) => setAlias(e.target.value)} />
                        <button disabled={!alias} className="btn btn-sm item-button-success" onClick={ () => update(el) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                          </svg>
                        </button>
                        <button className="btn btn-sm item-button" onClick={ () => cancelUpdate() } data-bs-toggle="tooltip" data-bs-placement="right" title={t('cancel')}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
                          </svg>
                        </button>
                      </div>}
                      {el.used && el.secret_expiry >= Date.now() && <div className="mt-1">
                        <i>{t('secret_code')}: {el.secret}</i>
                      </div>}
                      {el.used && el.secret_expiry >= Date.now() && <div className="mt-1">
                        <div className="affiliation-qrcode">
                          <QRCode value={'{"code":"'+el.code+'","secret":"'+el.secret+'"}'} size="100" />
                        </div>
                      </div>}
                      {el.used && el.secret_expiry < Date.now() && <div className="mt-1">
                        <button className="btn btn-sm item-button" onClick={ () => renewSecret(el) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('show_secret_code')}>
                          {t('show_secret_code')}
                        </button>
                      </div>}
                    </div>
                  </td>
                  <td style={{width: '200px',}}>
                    {cardLoading !== k && <div>
                      {el.used && <div className="d-flex justify-content-between">
                        <button disabled={values[k] <= 0 || parseFloat(values[k]) > parseFloat(el[props.service.code]?.balance) || (props.service.consumable && !el[props.service.code]?.consumed)} className="btn btn-sm item-button-danger" onClick={ () => confirmCard(() => consume(k, el, props.service.forceConsume ? -el[props.service.code]?.balance : -values[k])) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('consume')}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle" viewBox="0 0 16 16">
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                          </svg>
                        </button>
                        <input disabled={props.service.forceConsume && el[props.service.code]?.consumed} className="affiliation-input" type="number" min="0" step={props.service.currency ? 0.01 : 1} value={values[k]} onChange={(e) => {const v = [...values]; v[k] = e.target.value; setValues(v);}} />
                        <button disabled={values[k] <= 0 || (props.service.consumable && el[props.service.code]?.consumed)} className="btn btn-sm item-button-success" onClick={ () => confirmCard(() => add(k, el, values[k])) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('add')}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                          </svg>
                        </button>
                      </div>}
                      {!el.used && <div className="mt-2">
                        <i>{el.reserved ? t('airdrop_card_to_be_actived') : t('virtual_card_to_be_actived')}</i>
                      </div>}
                      {(el.used || !el.reserved) && <div className="mt-2">
                        <i>{t('balance')}: {props.service.currency ? (el[props.service.code]?.balance)?.toFixed(2)+' €' : el[props.service.code]?.balance}</i>
                      </div>}
                    </div>}
                    {cardLoading === k && <div className="spinner-border" style={{width: '3rem', height: '3rem',}}></div>}
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>}
          {!loading && data.length === 0 && <span>{t('no_cards')}</span>}
          {!loading && <div>
            <button className={page === 1 ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(1)}>1</button>
            {page-1 > 1 && <button className="btn item-button-inverse" onClick={() => reloadPage(page-1)}>...</button>}
            {page > 1 && page < pages && <button className="btn item-button" onClick={() => reloadPage(page)}>{page}</button>}
            {page+1 < pages && <button className="btn item-button-inverse" onClick={() => reloadPage(page+1)}>...</button>}
            {pages > 1 && <button className={page === pages ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(pages)}>{pages}</button>}
          </div>}
          {!loading && <div className="mt-4">
            <b>{t('total_balance')}: {props.service.currency ? (total).toFixed(2)+' €' : total}</b>
          </div>}
          {loading && <div className="text-center"><div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div></div>}
        </div>
        {data.filter((v) => !v?.digital).length > 0 && <div className="mt-4">
          <CSVLink className="btn item-button" data={data.filter((v) => !v?.digital).map((v) => {return {card: v.code}})} separator=";" filename="cards.csv">{t('download_physical_cards')}</CSVLink>
        </div>}
      </div>}
      {props.service?.code && store?.services && store.services[props.service.code] && membership?.role === 'admin' && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('activate_cards')}</h2>
        {cost > 0 && <p>{t('operation_cost', {cost: cost})}</p>}
        <p>{t('attention_card')}</p>
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('quantity')}</p>
              </td>
              <td>
                <select className="affiliation-input" value={quantity} onChange={e => setQuantity(e.target.value)}>
                  <option value="0">-</option>
                  {store?.tokens >= cost*50 && <option value="50">50 {t('cards')}</option>}
                  {store?.tokens >= cost*100 && <option value="100">100 {t('cards')}</option>}
                  {store?.tokens >= cost*250 && <option value="250">250 {t('cards')}</option>}
                  {store?.tokens >= cost*500 && <option value="500">500 {t('cards')}</option>}
                  {store?.tokens >= cost*1000 && <option value="1000">1000 {t('cards')}</option>}
                </select>
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('type')}</p>
              </td>
              <td>
                <select className="affiliation-input" value={digital} onChange={e => setDigital(e.target.value)}>
                  <option value="0">{t('physical_cards')}</option>
                  {!props.service.consumable && <option value="1">{t('virtual_cards')}</option>}
                  <option value="2">{t('air_drop')}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('start_value')}</p>
              </td>
              <td>
                <input className="affiliation-input" inputMode="numeric" pattern="[0-9]*" value={initialValue} onChange={(e) => setInitialValue(e.target.value ? parseInt(e.target.value.replace(/[^\d]+/g, '')) : '')} />
              </td>
            </tr>
            {digital === '2' && <tr>
              <td style={{width: '100px',}}>
                <p>{t('max_registrations')}</p>
              </td>
              <td>
                <input className="affiliation-input" inputMode="numeric" pattern="[0-9]*" value={maxRegistrations} onChange={(e) => setMaxRegistrations(e.target.value ? parseInt(e.target.value.replace(/[^\d]+/g, '')) : '')} />
              </td>
            </tr>}
            {digital === '2' && <tr>
              <td style={{width: '100px',}}>
                <p>{t('thresholds')}</p>
              </td>
              <td>
                <button className="btn item-button-success mb-2" onClick={ () => {const t = [...thresholds]; t.push({quantity: 1, value: 0}); setThresholds(t);} } data-bs-toggle="tooltip" data-bs-placement="right" title={t('add')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg> {t('add')}
                </button>
                <table className="table">
                  <tbody>
                    {thresholds.map((el, k) => (
                      <tr key={k}>
                        <td style={{width: '100px',}}>
                          <p>{t('value')}</p>
                        </td>
                        <td>
                          <input className="affiliation-input" inputMode="numeric" pattern="[0-9]*" min="0" value={el.value} onChange={(e) => {const t = [...thresholds]; t[k].value = e.target.value ? parseInt(e.target.value.replace(/[^\d]+/g, '')) : ''; setThresholds(t)}} />
                        </td>
                        <td style={{width: '100px',}}>
                          <p>{t('quantity')}</p>
                        </td>
                        <td>
                          <input className="affiliation-input" inputMode="numeric" pattern="[0-9]*" min="1" value={el.quantity} onChange={(e) => {const t = [...thresholds]; t[k].quantity = e.target.value ? parseInt(e.target.value.replace(/[^\d]+/g, '')) : ''; setThresholds(t)}} />
                        </td>
                        <td style={{width: '50px',}}>
                          <button className="btn btn-sm item-button-danger" onClick={ () => {const localThresholds = [...thresholds]; localThresholds.splice(k, 1); setThresholds(localThresholds);} } data-bs-toggle="tooltip" data-bs-placement="right" title={t('remove')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle" viewBox="0 0 16 16">
                              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>}
          </tbody>
        </table>
        <div className="mt-3">
          <button disabled={parseInt(quantity) <= 0 || initialValue < 0 || (digital === '2' && (maxRegistrations < 0 || thresholds.length === 0 || thresholds.filter((x) => x.value < 0 || x.quantity <= 0).length > 0))} className="btn btn-lg item-button" onClick={() => confirm(() => create())}>{t('confirm')}</button>
        </div>
        {cardsCreate && <small>{t('created_cards')}</small>}
      </div>}
      {data.filter((v) => !v?.used).length > 0 && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('activate_virtual_card')}</h2>
        <p>{t('remaining_cards')}: {data.filter((v) => !v?.used).length}</p>
        <p>{t('frame_qrcode')}</p>
        <div id="qrcode">
          <QRCode value={'{"service":"'+props.service.code+'","store":"'+store.code+'"}'} />
        </div>
      </div>}
    </section>
  );
}

export default Service;
