import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';

function Chats() {

  const [pages, setPages] = useState(1);
  const [data, setData] = useState([]);
  const [chats, setChats] = useState([]);
  const [device, setDevice] = useState('');
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(-1);

  const { t } = useTranslation();

  const [store, membership, loadUser] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || 1);

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const reloadPage = (p) => {
    setSearchParams(params => {
      params.set('page', p);
      return params;
    });
  };

  const createChat = async () => {
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const chat = await fetch(env.AUTH0_API_SERVER+"chats/"+store.code, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        device: device,
        text: text,
      }),
    }).then((res) => res.json());
    const currentChats = [...chats];
    currentChats.unshift(chat);
    setChats(currentChats);
    setText('');
    setLoading(false);
  };

  const updateDevice = async (val) => {
    setUpdating(-1);
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"devices/"+store.code+"/"+val, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        name: name,
      }),
    }).then((res) => res.json());
    await loadUser();
    setLoading(false);
  };

  const toggleUpdate = (k) => {
    setUpdating(k);
    setName(store.contacts[data[k]] ?? '');
  }

  const cancelUpdate = () => {
    setUpdating(-1);
    setName('');
  }

  const toggleDevice = async (val) => {
    setDevice(val);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"chats/"+store.code+"/"+val, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
    }).then((res) => res.json());
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        const localChats = await fetch(env.AUTH0_API_SERVER+"chats/"+window.sessionStorage.getItem('store'), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setChats(localChats);
        const devices = await fetch(env.AUTH0_API_SERVER+"devices/"+window.sessionStorage.getItem('store')+"/search?"+new URLSearchParams({page: page, per_page: isMobile ? 5 : 10}).toString(), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setPages(devices.pages);
        setData(devices.data);
        setLoading(false);
      }
    };
    initData();
  }, [auth, page])

  return (
    <section id="chats">
      {membership?.role === 'admin' && store.preferences.chat && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('chats')}</h2>
        <div className="mt-5">
          {!loading && <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('customers')}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            {data.length > 0 && <tbody>
              {data.map((el, k) => (
                <tr key={k}>
                  <td>
                    {updating !== k && <span>{(store.contacts[el] ?? el)+' ('+chats.filter((x) => x.device === el && x.sender === 'device' && !x.read_at).length+')'}</span>}
                    {updating !== k && <button className="btn btn-sm item-button" onClick={ () => toggleUpdate(k) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                      </svg>
                    </button>}
                    {updating === k && <input value={name} onChange={(e) => setName(e.target.value)} />}
                    {updating === k && <button disabled={!name} className="btn btn-sm item-button-success" onClick={ () => updateDevice(el) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('update')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                      </svg>
                    </button>}
                    {updating === k && <button className="btn btn-sm item-button" onClick={ () => cancelUpdate() } data-bs-toggle="tooltip" data-bs-placement="right" title={t('cancel')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
                        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
                      </svg>
                    </button>}
                  </td>
                  <td style={{width: '200px',}}>
                    <button className="btn btn-sm item-button" onClick={ () => toggleDevice(el) } data-bs-toggle="tooltip" data-bs-placement="right" title={t('show_conversation')}>
                      {t('show_conversation')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>}
          {!loading && data.length === 0 && <span>{t('no_chats')}</span>}
          {!loading && <div>
            <button className={page === 1 ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(1)}>1</button>
            {page-1 > 1 && <button className="btn item-button-inverse" onClick={() => reloadPage(page-1)}>...</button>}
            {page > 1 && page < pages && <button className="btn item-button" onClick={() => reloadPage(page)}>{page}</button>}
            {page+1 < pages && <button className="btn item-button-inverse" onClick={() => reloadPage(page+1)}>...</button>}
            {pages > 1 && <button className={page === pages ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(pages)}>{pages}</button>}
          </div>}
          {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
        </div>
      </div>}
      {membership?.role === 'admin' && store.preferences.chat && device &&  <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{(store.contacts[device] ?? device)}</h2>
        <input className="affiliation-input" type="text" value={text} onChange={(e) => setText(e.target.value)} placeholder={t('write')} />
        <div className="mt-3">
          <button disabled={!text} className="btn btn-lg item-button" onClick={() => confirm(() => createChat())}>{t('confirm')}</button>
        </div>
        {chats.filter((x) => x.device === device).map((el, k) => (
          <div style={el.sender === 'store' ? {marginLeft: 50} : {marginRight: 50}} className="affiliation-chat" key={k}>
            <p className="affiliation-chat-header">{new Date(el.date).toLocaleString()}</p>
            <p style={{textAlign: el.sender === 'store' ? 'right' : 'left'}}>{el.text}</p>
            <p className="affiliation-chat-footer">
              {el.read_at ? new Date(el.read_at).toLocaleString() : ''}
              {el.sender === 'store' && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={el.read_at ? "#EF78BC" : "currentColor"} className="bi bi-check-all" viewBox="0 0 16 16">
                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
              </svg>}
            </p>
          </div>
        ))}
      </div>}
    </section>
  );
}

export default Chats;
