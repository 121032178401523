import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import Resizer from "react-image-file-resizer";
import { useDebouncedCallback } from 'use-debounce';
import Swal from 'sweetalert2';

function Catalog() {

  const [pages, setPages] = useState(1);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(null);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [pricePrepaid, setPricePrepaid] = useState('');
  const [priceGiftCard, setPriceGiftCard] = useState('');
  const [pointsGained, setPointsGained] = useState('');
  const [validFrom, setValidFrom] = useState('');
  const [validTo, setValidTo] = useState('');
  const [duration, setDuration] = useState('');
  const [order, setOrder] = useState(0);
  const [image, setImage] = useState('');
  const [active, setActive] = useState('1');
  const [modal, setModal] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [store, membership] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || 1);
  const search = searchParams.get('search') || '';

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const reloadPage = (p) => {
    setSearchParams(params => {
      params.set('page', p);
      if (!!search) {
        params.set('search', search);
      }
      return params;
    });
  };

  const reloadSearch = useDebouncedCallback((s) => {
    setSearchParams(params => {
      params.set('page', 1);
      params.set('search', s);
      return params;
    });
  }, 500);

  const dismissModal = async () => {
    setIndex(null);
    setName('');
    setPrice('');
    setPricePrepaid('');
    setPriceGiftCard('');
    setPointsGained('');
    setValidFrom('');
    setValidTo('');
    setDuration('');
    setOrder(0);
    setImage('');
    setActive('0');
    setModal('');
  };

  const showModal = async (k) => {
    setIndex(k);
    setModal('show');
  };

  const createModal = async () => {
    setModal('form');
  };

  const updateModal = async (k) => {
    setIndex(k);
    setName(data[k].name);
    setPrice(data[k].price);
    setPricePrepaid(data[k].price_prepaid);
    setPriceGiftCard(data[k].price_gift_card);
    setPointsGained(data[k].points_gained);
    setValidFrom(data[k].valid_from ? new Date(data[k].valid_from).toISOString().split('T')[0] : '');
    setValidTo(data[k].valid_to ? new Date(data[k].valid_to).toISOString().split('T')[0] : '');
    setDuration(data[k].duration);
    setOrder(data[k].order);
    setImage(data[k].image);
    setActive(data[k].active ? '1' : '0');
    setModal('form');
  };

  const upsertCatalog = async () => {
    dismissModal();
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"catalogs/"+store.code+(index !== null ? "/"+data[index].id : ""), {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
      body: JSON.stringify({
        name: name,
        price: price,
        price_prepaid: pricePrepaid,
        price_gift_card: priceGiftCard,
        points_gained: pointsGained,
        valid_from: validFrom ? new Date(validFrom).valueOf() : '',
        valid_to: validTo ? new Date(validTo).valueOf() : '',
        duration: duration,
        order: order,
        active: active === '1',
        image: image,
      }),
    }).then((res) => res.json());
    reloadPage(page);
    setLoading(false);
  };

  const deleteCatalog = async (id) => {
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"catalogs/"+store.code+"/"+id, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'delete',
    }).then((res) => res.json());
    reloadPage(page);
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        const catalogs = await fetch(env.AUTH0_API_SERVER+"catalogs/"+window.sessionStorage.getItem('store')+"/search?"+new URLSearchParams({page: page, per_page: isMobile ? 5 : 10, search: search}).toString(), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setPages(catalogs.pages);
        setData(catalogs.data);
        setLoading(false);
      }
    };
    initData();
  }, [auth, page, search])

  return (
    <section id="catalog">
      {membership?.role === 'admin' && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('catalog')}</h2>
        <input id="search-input" placeholder={t('search')} onChange={(e) => reloadSearch(e.target.value)} />
        <div className="mt-3">
          <button className="btn btn-lg item-button-success" onClick={() => createModal()}>{t('create')}</button>
        </div>
        <div className="mt-5">
          {!loading && <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            {data.length > 0 && <tbody>
              {data.map((el, k) => (
                <tr key={k}>
                  <td>
                    <div className="mt-1">
                      <b>{el.name}</b>
                    </div>
                    <div className="mt-1">
                      {el.active && <span className="badge item-button-success">{t('active')}</span>}
                      {!el.active && <span className="badge item-button-danger">{t('not_active')}</span>}
                    </div>
                    <div className="mt-1">
                      {el.image ? <img src={el.image} alt="" height="100" /> : t('no_image')}<br />
                    </div>
                  </td>
                  <td style={{width: '200px',}}>
                    <div className="mt-1">
                      <button className="btn btn-sm item-button" onClick={() => showModal(k)}>{t('details')}</button>
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-sm item-button" onClick={() => updateModal(k)}>{t('update')}</button>
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-sm item-button-danger" onClick={() => confirm(() => deleteCatalog(el.id))}>{t('remove')}</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>}
          {!loading && data.length === 0 && <span>{t('no_catalog')}</span>}
          {!loading && <div>
            <button className={page === 1 ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(1)}>1</button>
            {page-1 > 1 && <button className="btn item-button-inverse" onClick={() => reloadPage(page-1)}>...</button>}
            {page > 1 && page < pages && <button className="btn item-button" onClick={() => reloadPage(page)}>{page}</button>}
            {page+1 < pages && <button className="btn item-button-inverse" onClick={() => reloadPage(page+1)}>...</button>}
            {pages > 1 && <button className={page === pages ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(pages)}>{pages}</button>}
          </div>}
          {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
        </div>
      </div>}
      {membership?.role === 'admin' && <Modal className="affiliation-modal text-center" isOpen={modal === 'form'} onRequestClose={() => dismissModal()} ariaHideApp={false} >
        <h2 className="affiliation-subtitle">{index !== null ? t('update') : t('create')}</h2>
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('name')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('price')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="0.01" inputMode="numeric" pattern="[0-9]*" min="0" value={price} onChange={(e) => setPrice(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('price_prepaid')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="0.01" inputMode="numeric" pattern="[0-9]*" min="0" value={pricePrepaid} onChange={(e) => setPricePrepaid(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('price_gift_card')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="0.01" inputMode="numeric" pattern="[0-9]*" min="0" value={priceGiftCard} onChange={(e) => setPriceGiftCard(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('points_gained')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="1" inputMode="numeric" pattern="[0-9]*" min="0" value={pointsGained} onChange={(e) => setPointsGained(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('start')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="date" value={validFrom} onChange={(e) => setValidFrom(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('end')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="date" value={validTo} onChange={(e) => setValidTo(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('duration')} ({t('minutes')})</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="5" inputMode="numeric" pattern="[0-9]*" min="0" value={duration} onChange={(e) => setDuration(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('active')}</p>
              </td>
              <td>
                <select className="affiliation-input" value={active} onChange={e => setActive(e.target.value)}>
                  <option value="0">{t('no')}</option>
                  <option value="1">{t('yes')}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('order')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="number" step="1" inputMode="numeric" pattern="[0-9]*" min="0" value={order} onChange={(e) => setOrder(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>
                <p>{t('image')}</p>
              </td>
              <td>
                {!image && <input className="affiliation-input" type="file" onChange={e => Resizer.imageFileResizer(e.target.files[0], 256, 256, "PNG", 100, 0, (x) => setImage(x), "base64", 256, 256)} />}
                {image && <img src={image} alt='' height='100' />}
                {image && <button className="btn btn-sm item-button-danger" onClick={ () => setImage('') } data-bs-toggle="tooltip" data-bs-placement="right" title={t('remove')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                  </svg>
                </button>}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3">
          <button disabled={!name || !price} className="btn btn-lg item-button" onClick={() => confirm(() => upsertCatalog())}>{t('confirm')}</button>
          <button className="btn btn-lg item-button" onClick={() => dismissModal()}>{t('back')}</button>
        </div>
      </Modal>}
      {membership?.role === 'admin' && index !== null &&  <Modal className="affiliation-modal text-center" isOpen={modal === 'show'} onRequestClose={() => dismissModal()} ariaHideApp={false} >
        <h2 className="affiliation-subtitle">{t('details')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width: '200px',}}>{t('name')}:</td>
              <td>{data[index].name}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('price')}:</td>
              <td>{(data[index].price*1).toFixed(2)} €</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('price')} {t('prepaid')}:</td>
              <td>{data[index].price_prepaid ? (data[index].price_prepaid*1).toFixed(2)+' €' : ''}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('price')} {t('gift-card')}:</td>
              <td>{data[index].price_gift_card ? (data[index].price_gift_card*1).toFixed(2)+' €' : ''}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('points_gained')}:</td>
              <td>{data[index].points_gained}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('start')}:</td>
              <td>{data[index].valid_from ? new Date(data[index].valid_from).toLocaleDateString([], {year: "numeric", month: "2-digit", day: "2-digit"}) : ''}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('end')}:</td>
              <td>{data[index].valid_to ? new Date(data[index].valid_to).toLocaleDateString([], {year: "numeric", month: "2-digit", day: "2-digit"}) : ''}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('duration')}:</td>
              <td>{data[index].duration ? data[index].duration+' '+t('minutes') : ''}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('active')}:</td>
              <td>{data[index].active ? t('yes') : t('no')}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('order')}:</td>
              <td>#{data[index].order}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('image')}:</td>
              <td>{data[index].image ? <img src={data[index].image} alt="" height="100" /> : t('no_image')}</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3">
          <button className="btn btn-lg item-button" onClick={() => dismissModal()}>{t('back')}</button>
        </div>
      </Modal>}
    </section>
  );
}

export default Catalog;
