import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import { useDebouncedCallback } from 'use-debounce';
import Swal from 'sweetalert2';

function Notifications() {

  const [cost, setCost] = useState(0);
  const [pages, setPages] = useState(1);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [url, setUrl] = useState('');
  const [modal, setModal] = useState('');
  const [validUrl, setValidUrl] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [store, membership, loadUser] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || 1);
  const search = searchParams.get('search') || '';

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      text: cost > 0 ? t('operation_cost', {cost: cost}) : '',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const reloadPage = (p) => {
    setSearchParams(params => {
      params.set('page', p);
      if (!!search) {
        params.set('search', search);
      }
      return params;
    });
  };

  const reloadSearch = useDebouncedCallback((s) => {
    setSearchParams(params => {
      params.set('page', 1);
      params.set('search', s);
      return params;
    });
  }, 500);

  const dismissModal = async () => {
    setIndex(null);
    setTitle('');
    setBody('');
    setUrl('');
    setModal('');
  };

  const showModal = async (k) => {
    setIndex(k);
    setModal('show');
  };

  const createModal = async () => {
    setModal('form');
  };

  const updateModal = async (k) => {
    setIndex(k);
    setTitle(data[k].title);
    setBody(data[k].body);
    setUrl(data[k].url);
    setModal('form');
  };

  const upsertNotification = async () => {
    setLoading(true);
    setValidUrl(true);
    if (url !== '' && !/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi.test(url)) {
      setValidUrl(false);
      setLoading(false);
    } else {
      dismissModal();
      const accessToken = await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: env.AUTH0_AUDIENCE,
          scope: "read:current_user",
        },
      });
      await fetch(env.AUTH0_API_SERVER+"notifications/"+store.code+(index !== null ? "/"+data[index].date : ""), {
        headers: {
          "Content-Type":"application/json",
          "Authorization": "Bearer "+accessToken,
        },
        method: 'post',
        body: JSON.stringify({
          title: title,
          body: body,
          url: url,
        }),
      }).then((res) => res.json());
      await loadUser();
      reloadPage(page);
      setLoading(false);
    }
  };

  const deleteNotification = async (id) => {
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"notifications/"+store.code+"/"+id, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'delete',
    }).then((res) => res.json());
    await loadUser();
    reloadPage(page);
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        const accessToken = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: env.AUTH0_AUDIENCE,
            scope: "read:current_user",
          },
        });
        setCost(0);
        //const priceList = await fetch(env.AUTH0_API_SERVER+"price-lists/notification", {
        //  headers: {
        //    "Content-Type":"application/json",
        //    "Authorization": "Bearer "+accessToken,
        //  },
        //}).then((res) => res.json());
        //setCost(priceList.cost);
        const notifications = await fetch(env.AUTH0_API_SERVER+"notifications/"+window.sessionStorage.getItem('store')+"/search?"+new URLSearchParams({page: page, per_page: isMobile ? 5 : 10, search: search}).toString(), {
          headers: {
            "Content-Type":"application/json",
            "Authorization": "Bearer "+accessToken,
          },
        }).then((res) => res.json());
        setPages(notifications.pages);
        setData(notifications.data);
        setLoading(false);
      }
    };
    initData();
  }, [auth, page, search])

  return (
    <section id="notifications">
      {membership?.role === 'admin' && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('notifications')}</h2>
        <input id="search-input" placeholder={t('search')} onChange={(e) => reloadSearch(e.target.value)} />
        <div className="mt-3">
          <button className="btn btn-lg item-button-success" onClick={() => createModal()}>{t('create')}</button>
        </div>
        <div className="mt-5">
          {!loading && <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            {data.length > 0 && <tbody>
              {data.map((el, k) => (
                <tr key={k}>
                  <td>
                    <div className="mt-1">
                      <b>{el.title}</b>
                    </div>
                    <div className="mt-1">
                      <small>{new Date(el.date).toDateString()}</small>
                    </div>
                  </td>
                  <td style={{width: '200px',}}>
                    <div className="mt-1">
                      <button className="btn btn-sm item-button" onClick={() => showModal(k)}>{t('details')}</button>
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-sm item-button" onClick={() => updateModal(k)}>{t('update')}</button>
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-sm item-button-danger" onClick={() => confirm(() => deleteNotification(el.date))}>{t('remove')}</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>}
          {!loading && data.length === 0 && <span>{t('no_notifications')}</span>}
          {!loading && <div>
            <button className={page === 1 ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(1)}>1</button>
            {page-1 > 1 && <button className="btn item-button-inverse" onClick={() => reloadPage(page-1)}>...</button>}
            {page > 1 && page < pages && <button className="btn item-button" onClick={() => reloadPage(page)}>{page}</button>}
            {page+1 < pages && <button className="btn item-button-inverse" onClick={() => reloadPage(page+1)}>...</button>}
            {pages > 1 && <button className={page === pages ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => reloadPage(pages)}>{pages}</button>}
          </div>}
          {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
        </div>
      </div>}
      {membership?.role === 'admin' && store?.tokens >= cost && <Modal className="affiliation-modal text-center" isOpen={modal === 'form'} onRequestClose={() => dismissModal()} ariaHideApp={false} >
        <h2 className="affiliation-subtitle">{t('create')}</h2>
        {cost > 0 && <p>{t('operation_cost', {cost: cost})}</p>}
        <table className="table">
          <tbody>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('title')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('text')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={body} onChange={(e) => setBody(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td style={{width: '100px',}}>
                <p>{t('url')}</p>
              </td>
              <td>
                <input className="affiliation-input" type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
              </td>
            </tr>
          </tbody>
        </table>
        {!validUrl && <small>{t('valid_url')}</small>}
        <div className="mt-3">
          <button disabled={!title || !body} className="btn btn-lg item-button" onClick={() => confirm(() => upsertNotification())}>{t('confirm')}</button>
        </div>
      </Modal>}
      {membership?.role === 'admin' && index !== null &&  <Modal className="affiliation-modal text-center" isOpen={modal === 'show'} onRequestClose={() => dismissModal()} ariaHideApp={false} >
        <h2 className="affiliation-subtitle">{t('details')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width: '200px',}}>{t('title')}:</td>
              <td>{data[index].title}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('text')}:</td>
              <td>{data[index].body}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('date')}:</td>
              <td>{data[index].date ? new Date(data[index].date).toLocaleDateString([], {year: "numeric", month: "2-digit", day: "2-digit"}) : ''}</td>
            </tr>
            <tr>
              <td style={{width: '200px',}}>{t('url')}:</td>
              <td>{data[index].url}</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3">
          <button className="btn btn-lg item-button" onClick={() => dismissModal()}>{t('back')}</button>
        </div>
      </Modal>}
    </section>
  );
}

export default Notifications;
