import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import Swal from 'sweetalert2';

function Services() {

  const [cost, setCost] = useState(0);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [store, membership, loadUser] = useOutletContext();

  const auth = useAuth0();

  const confirm = (callback) => {
    Swal.fire({
      title: t('are_you_sure'),
      text: cost > 0 ? t('operation_cost', {cost: cost}) : '',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#72C18B",
      cancelButtonColor: "#FF4D84",
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  };

  const createService = async (service) => {
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"services/"+store.code+"/"+service, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'post',
    }).then((res) => res.json());
    await loadUser();
    setLoading(false);
  };

  const deleteService = async (service) => {
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    await fetch(env.AUTH0_API_SERVER+"services/"+store.code+"/"+service, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
      method: 'delete',
    }).then((res) => res.json());
    await loadUser();
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('store')) {
        setLoading(true);
        //const accessToken = await auth.getAccessTokenSilently({
        //  authorizationParams: {
        //    audience: env.AUTH0_AUDIENCE,
        //    scope: "read:current_user",
        //  },
        //});
        setCost(0);
        //const priceList = await fetch(env.AUTH0_API_SERVER+"price-lists/service", {
        //  headers: {
        //    "Content-Type":"application/json",
        //    "Authorization": "Bearer "+accessToken,
        //  },
        //}).then((res) => res.json());
        //setCost(priceList.cost);
        setLoading(false);
      }
    };
    initData();
  }, [auth])

  return (
    <section id="home">
      {membership?.role === 'admin' && <div className="affiliation-section">
        <h2 className="affiliation-subtitle">{t('services')}</h2>
        {!loading && <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          {window.sessionStorage.getItem('services') && JSON.parse(window.sessionStorage.getItem('services')).length > 0 && <tbody>
            {JSON.parse(window.sessionStorage.getItem('services')).map((v, k) => (
              <tr key={k}>
                <td>
                  <h3>{t(v.code)}</h3>
                </td>
                <td>
                  {v.code in store.services && !!store.services[v.code] && <div>
                    <button className="btn item-button-danger" onClick={() => confirm(() => deleteService(v.code))}>{t('remove')}</button>
                    <p>
                      <small>{t('attention_service')}</small>
                    </p>
                  </div>}
                  {(!(v.code in store.services) || !store.services[v.code]) && <div>
                    <button className="btn item-button-success" onClick={() => confirm(() => createService(v.code), cost)} disabled={cost > store.tokens}>{t('activate')}</button>
                    {cost > 0 && <p>
                      <small>{t('operation_cost', {cost: cost})}</small>
                    </p>}
                  </div>}
                </td>
              </tr>
            ))}
          </tbody>}
        </table>}
        {loading && <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>}
      </div>}
    </section>
  );
}

export default Services;
